<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="방지시설 운전사항"
        tableId="grid"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :usePaging="false"
        :hideBottom="false"
        :columnSetting="false"
        :gridHeightAuto="param.isFullScreen"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="LBLSAVE" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-air-report-02',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envAirDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      listUrl: '',
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.air.daily.preventive.list.url;
      this.saveUrl = transactionConfig.env.air.daily.preventive.save.url;
      this.getColums();
    },
    getColums() {
      this.$http.url = selectConfig.env.air.mst.drug.list.url;
      this.$http.type = 'GET';
      this.$http.param = {plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid.columns = [
          {
            name: 'envAirMstPreventiveName',
            field: 'envAirMstPreventiveName',
            label: '방지시설',
            align: 'center',
            sortable: false,
          },
          {
            name: 'location',
            field: 'location',
            label: '설치위치',
            align: 'left',
            sortable: false,
          },
          {
            name: 'prevScore',
            field: 'prevScore',
            label: '전일지침',
            align: 'center',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'nowScore',
            field: 'nowScore',
            label: '금일지침',
            align: 'center',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'todayScore',
            field: 'todayScore',
            label: '사용량(kW/h)',
            align: 'center',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'envAirMstWattmeterNo',
            field: 'envAirMstWattmeterNo',
            label: '전력계번호',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'wattmeterMagnification',
            field: 'wattmeterMagnification',
            label: '배율',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'handlingCapacity',
            field: 'handlingCapacity',
            label: '처리용량(M3/MIN)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'handlingMaterial',
            field: 'handlingMaterial',
            label: '처리오염물질',
            align: 'center',
            sortable: false,
          },
          {
            name: 'handlingConcentration',
            field: 'handlingConcentration',
            label: '처리농도(ppm/mg/Sm3)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'handlingPercent',
            field: 'handlingPercent',
            label: '처리효율(%)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstDrug1Id',
            field: 'envAirMstDrug1Id',
            label: '사용약품1',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
            type: 'select',
            comboItems: _result.data,
          },
          {
            name: 'envAirMstDrug1Volume',
            field: 'envAirMstDrug1Volume',
            label: '사용량1',
            align: 'center',
            type: 'number',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'envAirMstDrug2Id',
            field: 'envAirMstDrug2Id',
            label: '사용약품2',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
            type: 'select',
            comboItems: _result.data,
          },
          {
            name: 'envAirMstDrug2Volume',
            field: 'envAirMstDrug2Volume',
            label: '사용량2',
            align: 'center',
            type: 'number',
            style: 'width: 80px',
            sortable: false,
          },
        ]
        this.getList();
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envAirDailyResultId: this.param.envAirDailyResultId, plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveData() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '저장되었습니다.', /* 저장되었습니다. */
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
    tableDataChange(props) {
      if (props.row['prevScore'] > 0 && props.row['nowScore'] > 0) {
        // this.$set(props.row, 'todayScore', props.row['nowScore'] - props.row['prevScore']);
        this.$set(props.row, 'todayScore', ((props.row['nowScore'] - props.row['prevScore']) * props.row['wattmeterMagnification']).toFixed(2));  // 배율 곱해서 계산
      } else if ((props.row['prevScore'] == 0 || props.row['prevScore'] === null) && props.row['nowScore'] > 0) {
        // this.$set(props.row, 'todayScore', props.row['nowScore']);
        this.$set(props.row, 'todayScore', (props.row['nowScore'] * props.row['wattmeterMagnification']).toFixed(2)); // 배율 곱해서 계산
      }
    },
  }
};
</script>
